<template>
  <v-card>
    <v-card-title class="px-2">
      {{ $t('Stock update list') }}
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        to="/reduce"
        class="d-none d-md-block pt-2"
      >
        <v-icon
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon><span class="d-none d-sm-block"> {{ $t('Create stock update list') }}</span>
      </v-btn>
      <v-btn
        color="primary"
        to="/reduce"
        class="d-block d-md-none pt-2"
        fab
        small
        outlined
      >
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-row class="px-2">
      <v-col
        cols="12"
        md="6"
        class="py-0"
        lg="4"
      >
        <v-text-field
          v-model="searchtext"
          :label="$t('search')"
          :placeholder="$t('wordingSearch')"
          dense
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :server-items-length="totalDataTableList"
      :footer-props="footer"
      hide-default-footer
      :loading="loading"
      disable-sort
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template #[`item.transfer_id_pri`]="{ index }">
        {{ segmentId + index + 1 }}
      </template>
      <template v-slot:[`item.transfer_id`]="{ item }">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'stockUpdateDetail', params: { id: item.transfer_id_pri } }"
        >
          {{ item.transfer_id }}
        </router-link>
        {{
          item.transfer_label_id == 1
            ? $t("requisition_drug_and_equipment")
            : ""
        }}
      </template>
      <template v-slot:[`item.transferorder_cost`]="{ item }">
        {{ item.transferorder_cost | formatPrice }}
      </template>
      <template #[`item.transfer_create`]="{ item }">
        <ConversDateTime :datetime="item.transfer_create" />
      </template>
      <template #[`item.actions`]="{item }">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'stockUpdateDetail', params: { id: item.transfer_id_pri } }"
        >
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("detail") }}</span>
          </v-tooltip>
        </router-link>

        <v-tooltip
          color="#212121"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              @click="print(item.transfer_id_pri)"
              v-on="on"
            >
              <v-icon>{{ icons.mdiPrinter }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("print") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <Pagination
      :page-data="options.page"
      :page-count="totalPage"
      :segment-id="+segmentId "
      :count-list="dataTableList.length"
      :total="+totalDataTableList"
      @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
      @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
    />
  </v-card>
</template>
<script>
import { mdiPlus, mdiInformationOutline, mdiPrinter } from '@mdi/js'
import useStockAdjust from './useStockAdjust'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import { formatPrice } from '@/plugins/filters'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    ConversDateTime,
    Pagination,
  },
  filters: {
    formatPrice,
  },

  setup() {
    return {
      ...useStockAdjust(),
      icons: {
        mdiPlus, mdiInformationOutline, mdiPrinter,
      },
    }
  },

}
</script>
