var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('Stock update list'))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"d-none d-md-block pt-2",attrs:{"color":"primary","to":"/reduce"}},[_c('v-icon',{staticClass:"me-1"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.$t('Create stock update list')))])],1),_c('v-btn',{staticClass:"d-block d-md-none pt-2",attrs:{"color":"primary","to":"/reduce","fab":"","small":"","outlined":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],1),_c('v-row',{staticClass:"px-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('search'),"placeholder":_vm.$t('wordingSearch'),"dense":"","outlined":""},model:{value:(_vm.searchtext),callback:function ($$v) {_vm.searchtext=$$v},expression:"searchtext"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"server-items-length":_vm.totalDataTableList,"footer-props":_vm.footer,"hide-default-footer":"","loading":_vm.loading,"disable-sort":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.transfer_id_pri",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.segmentId + index + 1)+" ")]}},{key:"item.transfer_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'stockUpdateDetail', params: { id: item.transfer_id_pri } }}},[_vm._v(" "+_vm._s(item.transfer_id)+" ")]),_vm._v(" "+_vm._s(item.transfer_label_id == 1 ? _vm.$t("requisition_drug_and_equipment") : "")+" ")]}},{key:"item.transferorder_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.transferorder_cost))+" ")]}},{key:"item.transfer_create",fn:function(ref){
var item = ref.item;
return [_c('ConversDateTime',{attrs:{"datetime":item.transfer_create}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'stockUpdateDetail', params: { id: item.transfer_id_pri } }}},[_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiInformationOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("detail")))])])],1),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.print(item.transfer_id_pri)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPrinter))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("print")))])])]}}],null,true)}),_c('v-divider'),_c('Pagination',{attrs:{"page-data":_vm.options.page,"page-count":_vm.totalPage,"segment-id":+_vm.segmentId,"count-list":_vm.dataTableList.length,"total":+_vm.totalDataTableList},on:{"pageChanged":function (page){_vm.options.page = page ; _vm.fetchDataTable()},"itemChanged":function (items){_vm.options.itemsPerPage = items;_vm.options.page=1 ; _vm.fetchDataTable()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }