import stockAdjust from '@/api/DrugManagement/stockAdjust'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import { ref, watch } from '@vue/composition-api'

export default function useStockAdjust() {
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const totalPage = ref(0)
  const loading = ref(false)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'transfer_id_pri',
      width: 30,
    },
    {
      text: i18n.t('a_number'),
      value: 'transfer_id',
      width: 150,
    },
    {
      text: i18n.t('cost_price'),
      value: 'transferorder_cost',
      width: 120,
      align: 'end',
    },
    {
      text: i18n.t('stock_update_date'),
      value: 'transfer_create',
      width: 150,
    },
    {
      text: i18n.t('updated_by'),
      value: 'user_fullname',
      width: 150,
    },
    {
      text: i18n.t('note'),
      width: 200,
      value: 'transfer_comment',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 150,
      sortable: false,
      align: 'center',
    },
  ])
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const options = ref({})
  const segmentId = ref(0)

  const searchtext = ref('')

  const { stockAdjustList } = stockAdjust

  const fetchDataTable = () => {
    loading.value = true
    stockAdjustList({
      searchtext: searchtext.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data
      totalPage.value = count_of_page
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }

  watch([searchtext, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  const print = id => {
    const ids = [id]
    const routeData = router.resolve({
      name: 'printReduce',
      query: {
        id: String(ids),
      },
    })
    window.open(routeData.href, '_blank')
  }

  return {
    dataTableList,
    totalDataTableList,
    loading,
    columns,
    footer,
    totalPage,
    options,
    segmentId,
    searchtext,
    fetchDataTable,
    print,
  }
}
